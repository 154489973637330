import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import Box from 'shared/components/common/box/Box';
import BoxBody from 'shared/components/common/box/BoxBody';
import BoxContent from 'shared/components/common/box/BoxContent';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxRow from 'shared/components/common/box/BoxRow';
import Breadcrumb from 'shared/components/common/breadcrumb/Breadcrumb';
import ContentLayout from 'shared/components/common/content-layout/ContentLayout';
import CommonQrReader from 'shared/components/common/qr-reader/CommonQrReader';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import FormInput from 'shared/components/custom-form/FormInput';
import Button from 'shared/components/form-input/button/Button';
import classes from './FormWarehouseIn.module.scss';
import CustomTable from 'shared/components/common/custom-table/CustomTable';
import FormFileWarehouse from 'shared/components/custom-form/FormFileWarehouse';
import Validator from 'utils/Validator';
import IconChevronRight from 'assets/icon/IconChevronRight';
import IconChevronDown from 'assets/icon/IconChevronDown';
import Constants from 'utils/Constants';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getMessageCommon, showPopupNotification } from 'utils/Utils';
import actionWareHouse from 'redux/warehouse-in/action';
import { useParams } from 'react-router-dom';

function FormWarehouseIn(props) {
    const {
        minWidth = 250,
        propsLayout,
    } = props;
    const { t } = useTrans();

    const { id } = useParams();
    const methods = useForm();
    const methodsScan = useForm();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [loadingInfo, setLoadingInfo] = useState(false)
    const [infoWarehouse, setInfoWarehouse] = useState(null)
    const [products, setProducts] = useState([])

    const handleSubmit = (data) => {
        showPopupNotification({
            typePopup: 'confirm',
            message: Validator.renderMessage(t("I0006"), {
                0: t("warehouse_in.in_confirm")
            }),
            callback: () => {
                setLoading(true)
                dispatch({
                    type: actionWareHouse.CREATE_WAREHOUSE_IN,
                    payload: {
                        ...data,
                        products: data?.products?.map(x => {
                            return {
                                productId: x?.productId,
                                quantity: x?.quantity,
                            }
                        }),
                    },
                    callback: {
                        success: (data, error) => {
                            setLoading(false);
                            showPopupNotification({
                                typePopup: 'message',
                                message: getMessageCommon({
                                    response: data,
                                    error: error,
                                    actionName: t("warehouse_in.in_confirm")
                                }),
                                newWindow: true,
                            })

                            methods.reset({});
                            methodsScan.setValue("Scan", "");
                            setProducts([]);
                            setInfoWarehouse(null)
                        },
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (id) {
            methodsScan.setValue("Scan", id)
            setLoadingInfo(true)
            dispatch({
                type: actionWareHouse.GET_WAREHOUSE_IN_BARCODE,
                payload: id,
                callback: {
                    success: (data) => {
                        setLoadingInfo(false)
                        if (data?.errorCode) {
                            showPopupNotification({
                                typePopup: 'message',
                                message: data?.errorCode
                            })
                        } else {
                            setProducts(data?.products);
                            setInfoWarehouse(data);
                            methods.setValue("products", data?.products)
                            methods.setValue("requestInventoryManagement", data?.requestInventoryManagement == "1" ? true : false)
                            methods.setValue("requestInspection", data?.requestInspection == "1" ? true : false)
                            methods.setValue("hexOrderNo", data?.hexOrderNo)
                            methods.setValue("cargoNo", data?.cargoNo)
                            methods.setValue("inSlipNumber", data?.inSlipNumber)
                            methods.setValue("senderName", data?.senderName)
                        }
                    },
                    failed: () => {
                        setLoadingInfo(true)
                    }
                }
            })
        }
    }, [id])

    const handleGetDataFromBarcode = (e) => {
        setLoadingInfo(true);
        dispatch({
            type: actionWareHouse.GET_WAREHOUSE_IN_BARCODE,
            payload: e,
            callback: {
                success: (data) => {
                    setLoadingInfo(false)
                    if (data?.errorCode) {
                        showPopupNotification({
                            typePopup: 'message',
                            message: data?.errorCode
                        });
                        setInfoWarehouse(null);
                        methods.reset({})
                    } else {
                        setInfoWarehouse(data)
                        setProducts(data?.products);
                        methods.setValue("products", data?.products)
                        methods.setValue("requestInventoryManagement", data?.requestInventoryManagement == "1" ? true : false)
                        methods.setValue("requestInspection", data?.requestInspection == "1" ? true : false)
                        methods.setValue("hexOrderNo", data?.hexOrderNo)
                        methods.setValue("cargoNo", data?.cargoNo)
                        methods.setValue("inSlipNumber", data?.inSlipNumber)
                        methods.setValue("senderName", data?.senderName)

                    }
                },
                failed: () => {
                    setLoadingInfo(true);
                    setInfoWarehouse(null)
                }
            }
        })
    }



    const onSubmitScan = (data) => {
        handleGetDataFromBarcode(data?.Scan);
    }

    const [open, setOpen] = useState(false)
    const [openQr, setOpenQR] = useState(false)

    const headCell = [
        {
            label: t("product.gs1_code"),
            field: "productCode",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t("product.product_name"),
            field: "productName",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'left',
            alignHeader: 'left',
        },
        {
            label: t("product.quantity"),
            field: "quantity",
            minWidth: '150px',
            width: '150px',
            maxWidth: '150px',
            styleBody: {
                padding: '5px 10px'
            },
            styleHeader: {
                padding: '10px 10px'
            },
            align: 'right',
            alignHeader: 'right',
        },
    ];

    return (
        <ContentLayout {...propsLayout}>
            <Breadcrumb>{t("warehouse.incoming_inventory_management")}</Breadcrumb>
            <CustomFormProvider {...methodsScan}>
                <form className={classes['form-action']} onSubmit={methodsScan.handleSubmit(onSubmitScan)}>
                    <BoxRow style={{
                        flexWrap: 'nowrap',
                        gap: 20
                    }}>
                        <FormInput
                            validationFE
                            validate={[]}
                            isRegexLabel=""
                            fieldName='Scan'
                            disabled={id ? true : false}
                            placeholder={t("warehouse_in.place_holder")}
                        />
                        <Button className='btn-form' disabled={id ? true : false} loading={loadingInfo} type='button' onClick={() => setOpenQR(!open)} title={t("warehouse_in.scan")} />
                    </BoxRow>
                </form>
            </CustomFormProvider>
            <CustomFormProvider {...methods}>
                <form className={classes['quick-submit']} onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Box>
                        <BoxBody padding='10px 0px'>
                            {
                                openQr && <BoxRow style={{
                                    justifyContent: 'center'
                                }}>
                                    <CommonQrReader openQR={openQr} handleOpenQrCode={(e) => setOpenQR(e)} callback={(e) => {
                                        methodsScan.setValue("Scan", e);
                                        handleGetDataFromBarcode(e)
                                    }} />
                                </BoxRow>
                            }
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.hex_order_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.hex_order_number")}
                                        fieldName='hexOrderNo'
                                        disabled
                                        placeholder={t("warehouse_in.hex_order_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.cargo_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.cargo_number")}
                                        fieldName='cargoNo'
                                        disabled
                                        placeholder={t("warehouse_in.cargo_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.cargo_receipt_number")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.cargo_receipt_number")}
                                        fieldName='inSlipNumber'
                                        disabled
                                        placeholder={t("warehouse_in.cargo_receipt_number")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.sender")}</BoxLabel>
                                <BoxContent>
                                    <FormInput
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.sender")}
                                        fieldName='senderName'
                                        disabled
                                        placeholder={t("warehouse_in.sender")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth} isRequired>{t("warehouse_in.in_stock_photo")}</BoxLabel>
                                <BoxContent>
                                    <FormFileWarehouse
                                        validationFE
                                        validate={[]}
                                        isRegexLabel={t("warehouse_in.in_stock_photo")}
                                        fieldName='attachments'
                                        format={infoWarehouse?.cargoHexId ? `${infoWarehouse?.cargoHexId}_in_[ddMMyy(date)]_[HHmmss(date)].[ext]` : null}
                                        placeholder={t("warehouse_in.in_stock_photo")} />
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.inventory_management_desired")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestInventoryManagementName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            {
                                infoWarehouse?.requestInventoryManagement == Constants.STATUS_CHECKBOX.YES.VALUE && <BoxRow style={{
                                    flexDirection: 'column',
                                    gap: 10
                                }}>
                                    <BoxLabel style={{
                                        gap: 10,
                                        cursor: 'pointer'
                                    }} onClick={() => setOpen(!open)}>
                                        {
                                            open ? <IconChevronDown /> : <IconChevronRight fontSize={12} />
                                        }
                                        {t("warehouse_in.product_list")}
                                    </BoxLabel>
                                    {
                                        open && <CustomTable data={[...products]} headCell={headCell} />
                                    }
                                </BoxRow>
                            }
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.inspection_quantity_confirm")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestInspectionQuantityName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.inspection_pick_up_work")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestInspectionRandomName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.photo_memo")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestTakePhotoName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.reinforced_packaging")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestReinforcedPackagingAirCushionName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.enhanced_packaging")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.requestReinforcedPackagingWrappingName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.special_shipping_handling")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.specialOutStockName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                            <BoxRow>
                                <BoxLabel minWidth={minWidth}>{t("warehouse_in.throw_away_the_receipt")}</BoxLabel>
                                <BoxContent>
                                    {
                                        infoWarehouse?.receiptThrowAwayName ?? ''
                                    }
                                </BoxContent>
                            </BoxRow>
                        </BoxBody>
                    </Box>
                    <div className={classes['action-button']}>
                        <Button className='btn-action' loading={loading} title={t("warehouse_in.recept_information")} type='submit' />
                    </div>
                </form>
            </CustomFormProvider>
        </ContentLayout>
    )
}

export default FormWarehouseIn